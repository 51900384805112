import React from 'react';
import { GatsbyLinkProps, navigate } from 'gatsby';

// escape webpack.resolve.alias
export * from '../../../../node_modules/react-router-dom';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface Props extends Omit<GatsbyLinkProps<any>, 'ref'> {
    component: any;
}

// https://github.com/ReactTraining/react-router/blob/f634c59f585d77ecaf95993bbc42c0c494d3c31d/packages/react-router-dom/modules/Link.js#L74
// https://gitlab.m2.ru/vtblife/frontend/libraries/uikit/-/blob/5cea0fec705353c99462999172d4fcfe72b785f1/packages/ui-kit/src/components/buttons/base/index.tsx#L90-99
export const Link: React.FC<Props> = ({ component, to, ...props }) => {
    if (component) {
        const nextProps = {
            ...props,
            href: to,
            navigate: () => navigate(to),
        };
        return React.createElement(component, nextProps);
        // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
    } else {
        return <a {...props} href={to} />;
    }
};

export const NavLink: React.FC<Props> = props => {
    return <Link {...props} />;
};
