import avitoDesktop from './avito_desktop.component.svg';
import avitoTouch from './avito_touch.component.svg';
import sravniruDesktop from './sravniru_desktop.component.svg';
import sravniruTouch from './sravniru_touch.component.svg';
import groupDesktop from './group_desktop.component.svg';
import groupTouch from './group_touch.component.svg';
//import m2NYLightDesktop from './m2_ny_light_desktop.component.svg';
//import m2NYLightTouch from './m2_ny_light_touch.component.svg';
//import m2NYDesktop from './m2_ny_desktop.component.svg';
//import m2NYTouch from './m2_ny_touch.component.svg';
import m2Desktop from './m2_desktop.component.svg';
import m2Touch from './m2_touch.component.svg';
import m2ProTouch from './m2-pro_touch.component.svg';
import vtbDesktop from './vtb_desktop.component.svg';
import vtbTouch from './vtb_touch.component.svg';
import aeonLogo from '../../../images/aeon-logo.component.svg';

export type LogoVariant = 'avito' | 'group' | 'm2' | 'm2-light' | 'vtb' | 'aeon' | 'm2-pro' | 'samolet' | 'sravniru';

type LogoImage = {
    desktop: string;
    touch: string;
};

export const LogoImages: Record<LogoVariant, LogoImage> = {
    avito: {
        desktop: avitoDesktop,
        touch: avitoTouch,
    },
    sravniru: {
        desktop: sravniruDesktop,
        touch: sravniruTouch,
    },
    group: {
        desktop: groupDesktop,
        touch: groupTouch,
    },
    m2: {
        desktop: m2Desktop,
        touch: m2Touch,
    },
    'm2-light': {
        desktop: m2Desktop,
        touch: m2Touch,
    },
    'm2-pro': {
        desktop: m2Desktop,
        touch: m2ProTouch,
    },
    vtb: {
        desktop: vtbDesktop,
        touch: vtbTouch,
    },
    aeon: {
        desktop: m2Desktop,
        touch: aeonLogo,
    },
    samolet: {
        desktop: m2Desktop,
        touch: m2Touch,
    },
};
