import React, { useEffect } from 'react';
import { toast, ToastOptions, ToastContainer, ToastContainerProps } from 'react-toastify';
import Sentry from '@sentry/browser';
import { NotificationPayload } from '@vtblife/event-bus-events';

import { CloseControl } from '../components/close-control/close-control';
import { TOAST_CONFIG, TOAST_CONTAINER_CONFIG } from '../toastify-config';
import { ToasterContent } from '../components/toaster-content/toaster-content';
import 'react-toastify/dist/ReactToastify.css';
import './toaster-adapter-global.module.css';
import styles from './toaster-adapter.module.css';

const handleInvalidMessage = (payload: NotificationPayload): void => {
    const errorMessage = 'Root NotificationToaster: Received content is not valid ReactNode!';
    console.error(errorMessage, 'Received payload:', payload);
    Sentry?.withScope((scope) => {
        scope.setExtras({ receivedNotificationPayload: payload });
        Sentry?.captureMessage(errorMessage, 'warning');
    });
};

export function showToasterNotification({ type = 'default', message, options = {} }: NotificationPayload) {
    try {
        if (typeof message !== 'string' && !React.isValidElement(message)) {
            handleInvalidMessage({ type, message, options });
            return;
        }
        //Не показываем ошибки6 если они отключены в sessionStorage
        if (
            type === 'error' &&
            window?.sessionStorage &&
            window.sessionStorage.getItem('disable-error-notifications')
        ) {
            console.debug('ignored error message', message);
            return;
        }
        const toastOptions: ToastOptions = {
            ...TOAST_CONFIG,
            className: styles[type] || styles.default,
            closeButton: <CloseControl onClick={options.onClickCloseButton} notificationType={type} />,
            ...options,
        };
        return toast(
            <ToasterContent title={options.title} footerFn={options.footerFn}>
                {message}
            </ToasterContent>,
            toastOptions,
        );
    } catch (err) {
        throw new Error('Root NotificationToaster: something went wrong: ' + err);
    }
}

export const ToasterContainer = (props: ToastContainerProps) => {
    const clearOnUnmount = () => {
        return () => {
            toast.dismiss();
        };
    };
    useEffect(clearOnUnmount, []);

    return <ToastContainer {...TOAST_CONTAINER_CONFIG} {...props} />;
};
