import { AxiosError } from '@vtblife/axios';

import { showToasterNotification } from '../components/notification-toaster/toaster-adapter/toaster-adapter';
import { reportErrorToSentry } from './sentry-reporter';
import { composeNotification } from './compose-notification';
import { showQratorBlockReportModal } from '../components/report-modal/show-qrator-report-modal';
import { MESSAGES } from '../../constants';

export interface AdditionalClientDataService {
    getData(): { [key: string]: any };
}

const isGtmError = (error?: AxiosError) => error?.stack?.includes('googletagmanager.com/gtm.js');
export const isBlockedByQrator = (error?: AxiosError<any>) => {
    const { response } = error || {};
    const { data, status } = response || {};
    return status === 403 && typeof data === 'string' && data.includes('Reason or support ID');
};

export const extractQratorTransactionId = (error?: AxiosError<any>): string | null => {
    const data = error?.response?.data;
    if (typeof data === 'string') {
        const regExp = /.*support ID: (\S*)\..*/i;
        const matchResult = data.match(regExp);
        return matchResult && matchResult.length > 0 ? matchResult[1] : null;
    }
    return null;
};

export const configureUnhandledErrorsNotification = (
    additionalClientDataService?: AdditionalClientDataService,
    extra?: Record<string, any>,
    displayToasterNotification?: boolean,
) => {
    const unhandledPromiseRejectionHandler = async (event: PromiseRejectionEvent) => {
        // нам не нужен полный url, тк мы определяем только абсолютный он или нет
        const axiosRequestBaseUrl = event.reason?.config?.baseUrl || event.reason?.config?.url;
        const isTypeError = event.reason instanceof TypeError;
        const isFetchError = event.reason?.message === 'Failed to fetch';
        //Игнорируем ошибки в промисах на неизвестные домены, не игнорируем обычные ошибки
        if (!axiosRequestBaseUrl && (!isTypeError || isFetchError)) {
            return;
        }

        const linkRegexp = new RegExp(/(https?:\/\/[^\s]+)/g);

        // игнорируем ошибки, где запрос шел не на текущий хост
        // если requestUrl не ссылка, значит это относительный путь и запрос точно на текущий хост
        if (linkRegexp.test(axiosRequestBaseUrl)) {
            const host = new URL(axiosRequestBaseUrl).host;
            if (!host.includes(window.location.host)) {
                return;
            }
        }
        const { sentryEventId, extractedData } = await reportErrorToSentry({
            error: event.reason,
            extra,
            additionalData: additionalClientDataService?.getData(),
            description:
                'A promise rejection handled by `window.addEventListener("unhandledrejection")`. See the additional data for more details.',
        });

        if (isBlockedByQrator(event.reason)) {
            showQratorBlockReportModal(extractedData, extra, sentryEventId, event.reason);
            return;
        }

        if (displayToasterNotification && !isGtmError(event.reason)) {
            showToasterNotification(composeNotification(extractedData, sentryEventId));
        }
    };

    const unknownErrorHandler = async (event: ErrorEvent): Promise<void> => {
        const isCaptchaError = event.error?.stack?.includes('https://smartcaptcha.yandexcloud.net/captcha.js');
        const isResizeObserverError = event.message === 'ResizeObserver loop completed with undelivered notifications.';
        const { extractedData } = await reportErrorToSentry({
            error: event.error || event,
            extra,
            additionalData: additionalClientDataService?.getData(),
            description:
                'An error handled by `window.addEventListener("error")`. See the attached event for more details.',
        });

        // Считаем ли нужным показывать тост с ошибкой
        const isActionableError =
            !isGtmError(event.error) &&
            !isCaptchaError &&
            !isResizeObserverError &&
            extractedData.ui?.message !== MESSAGES.defaultError;

        if (displayToasterNotification && isActionableError) {
            showToasterNotification({
                type: 'error',
                message: extractedData.ui?.message,
                options: { toastId: `unknown_error_${extractedData?.data?.parsedType}` }, // to avoid toast duplicates. Only one toast per each type
            });
        }
    };

    window.addEventListener('unhandledrejection', unhandledPromiseRejectionHandler);
    window.addEventListener('error', unknownErrorHandler);
    if (typeof navigator !== 'undefined' && typeof navigator.serviceWorker !== 'undefined') {
        navigator.serviceWorker.addEventListener('message', async (event) => {
            if (typeof event.data === 'object' && event.data.type === 'SENTRY') {
                reportErrorToSentry({
                    error: event.data.error,
                    extra: {
                        tags: event.data.tags,
                    },
                });
            }
        });
    }
};
