import React from 'react';
import cn from 'classnames';
import { NotificationPayload } from '@vtblife/event-bus-events';
import Icon from '@vtblife/uikit-icons';

import { ToastifyPassedProps } from '../../toastify-config';
import styles from './close-control.module.css';

export const CloseControl: React.FC<
    {
        notificationType?: NotificationPayload['type'];
        onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    } & ToastifyPassedProps
> = ({ closeToast, notificationType = 'default', onClick }) => {
    const controlCls = cn({
        [styles.control]: true,
        [styles[notificationType] || styles.default]: !!notificationType,
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(event);
        closeToast?.();
    };

    return (
        <button
            className={controlCls}
            type="button"
            aria-label="Закрыть"
            data-test="close-notification-control"
            onClick={handleClick}
        >
            <Icon name="times" size="s" />
        </button>
    );
};
