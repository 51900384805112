import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import { UnregisterCallback } from 'history';
import { EventBus } from '@vtblife/event-bus';
import {
    AppContextChanged,
    AuthFinished,
    AuthFinishedEventData,
    AuthFinishedEventType,
    AuthInfo,
    AuthInitialize,
    AuthInitializeEventData,
    AuthInitializeEventType,
    AuthLoadedEventType,
    CheckSessionExpiredEventType,
    NotificationEvent,
    NotificationPayload,
    RestoreExpiredSessionEventData,
    RoleType,
    User,
    UserDataChangedEventType,
} from '@vtblife/event-bus-events';
import * as Sentry from '@sentry/browser';
import { SessionExpiredEventType } from '@vtblife/event-bus-events/dist/auth/session-expired';
import { reportErrorToSentry } from '@vtblife/layout-components/shell/utils';
import { getLastAuth, LAST_AUTH_LOCAL_STORAGE_KEY } from '@vtblife/layout-components/helpers';
import { isAnonymousUser } from '@vtblife/layout-components/utils/is-anonymous-user';
import { isBrowser } from '@vtblife/layout-components/utils';

import { ModelProfile, getProfile } from '@vtblife/profile-api/axios-gen/user_profile_service';

import {
    V1VerificationStatus,
    getVerificationStatus,
} from '@vtblife/client-verification-api/axios-gen/m2pro_verification_service';
import {
    V1VerificationStatus as KycVerificationStatus,
    findVerificationStatus as findKycVerificationStatus,
} from '@vtblife/client-verification-api/axios-gen/kyc_verification_service';

import { RootStore } from './root';
import { authApiService } from '../services/auth-api-service';
import { authzApiService } from '../services/authz-api-service';
import { isAllowedByPermissions, isAllowedByUserRoleTypes, isAuthorizedPath } from '../utils';

export interface KycVerificationReminderData {
    isBlocker: boolean;
    mode: 'hidden' | 'modal' | 'header';
}

export class UserStore {
    private authInitializeData: AuthInitializeEventData | RestoreExpiredSessionEventData | undefined;
    private eventBus = EventBus.getInstance();

    rootStore: RootStore;

    private historyStoreListener: UnregisterCallback;
    private shouldReloadAfterAuthFinished = false;

    @observable needLoadAuth = false;
    @observable user: User | null;
    @observable sessionExpired = false;
    @observable redirectPath = '';
    @observable initialized = true;
    @observable onAuthFinishedRedirectPath: string | undefined;
    @observable m2ProClientVerificationStatus?: V1VerificationStatus;
    @observable kycVerificationStatus?: KycVerificationStatus;
    @observable kycVerificationReminder?: KycVerificationReminderData;
    @observable profile?: ModelProfile;

    @computed
    get isAuthorized(): boolean {
        return Boolean(this.user?.isAuthenticated);
    }

    @computed get isInitialized() {
        return Boolean(this.initialized);
    }

    @computed
    get isAnonymousUser(): boolean {
        return isAnonymousUser(this.user?.userId);
    }

    constructor(rootStore: RootStore, initialState?: any) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
        }

        if (isBrowser()) {
            reaction(
                () => this.user,
                async () => {
                    // this.publishAppContextChanged() notifies all subscribers synchronously.
                    // So it must be the first thing to do to enforce the app state consistency.
                    // For more details see the bug https://jira.m2.ru/browse/LK-2653 and related MR.
                    this.publishAppContextChanged();
                    await this.rootStore.configStore.fetchConfig();
                },
            );

            reaction(
                () => this.user?.userId,
                async () => {
                    this.fetchAuthData();
                },
                { fireImmediately: true },
            );
            reaction(
                () => this.sessionExpired,
                (sessionExpired) => {
                    const disableNotificationKey = 'disable-error-notifications';
                    if (sessionExpired) {
                        toast.dismiss();
                        window?.sessionStorage?.setItem(disableNotificationKey, 'disable');
                    } else {
                        window?.sessionStorage?.removeItem(disableNotificationKey);
                    }
                },
                { fireImmediately: true },
            );

            this.subscribeUserDataChanged();
            this.subscribeSessionExpired();
            this.subscribeToHistoryChange();
            this.subscribeToAuthLoaded();
            this.subscribeToAuthInitialize();
            this.subscribeToAuthFinished();
            this.subscribeToCheckSessionExpired();
            this.subscribeShowKycVerificarionReminder();

            reaction(
                () => this.isAuthorized,
                () => {
                    this.setUpSentryContext();
                    this.setUpWebAnalytics();
                },
                {
                    fireImmediately: true,
                },
            );

            window.addEventListener('storage', this.storageListener);
            window.addEventListener('focus', this.fetchAuthData);
        }
    }

    cleanUp() {
        this.historyStoreListener();
        window.removeEventListener('storage', this.storageListener);
        window.removeEventListener('focus', this.fetchAuthData);
    }

    setInitialState(initialState: any) {
        Object.assign(this, initialState);
    }

    @action
    setRedirectPath(redirectPath: string) {
        this.redirectPath = redirectPath;
    }

    @action
    closeKycVerificationReminder = () => {
        this.kycVerificationReminder = { isBlocker: false, mode: 'hidden' };
    };

    @action
    async logout() {
        this.initialized = false;
        await authApiService.logout();
        runInAction(() => {
            this.user = null;
            this.onAuthFinishedRedirectPath = undefined;
        });
    }

    storageListener = (event: StorageEvent) => {
        if (
            event.key === LAST_AUTH_LOCAL_STORAGE_KEY &&
            event.newValue &&
            this.isAuthorized &&
            !getLastAuth()?.isAuthorized
        ) {
            runInAction(() => {
                this.user = null;
            });
        }
    };

    checkIfSessionExpired = async (shouldReload?: boolean) => {
        const { pathname } = this.rootStore.historyStore.location;
        const isAuthorizedRoute = isAuthorizedPath(pathname, this.rootStore.bundleTreeStore.bundleTreeRoutes);

        if (this.isAuthorized && isAuthorizedRoute) {
            try {
                const authInfo = await authzApiService.getAuthInfo();
                if (!authInfo?.user?.isAuthenticated) {
                    this.sessionExpired = true;
                    const authInitializeData: RestoreExpiredSessionEventData = {
                        flow: 'restore-expired-session',
                        user: toJS(this.user!),
                    };
                    this.authInitializeData = authInitializeData;
                    this.needLoadAuth = true;
                    this.shouldReloadAfterAuthFinished = Boolean(shouldReload);
                }
            } catch (error) {
                reportErrorToSentry({ error, description: 'Failed to check session status' });
            }
        }
    };

    toJS() {
        return {
            user: toJS(this.user),
            isAuthenticated: this.isAuthorized,
        };
    }

    isAllowed = ({
        requiredPermissions = [],
        requiredRoleTypes = [],
        companyIds,
        blockCompanyIds,
    }: {
        requiredPermissions?: string[];
        requiredRoleTypes?: RoleType[];
        companyIds?: string[];
        blockCompanyIds?: string[];
    }) => {
        const { permissions, currentRoleType, companyId } = this.user || {};

        const lockByBlockIds = blockCompanyIds?.length && companyId && blockCompanyIds.includes(companyId);
        const lockByCompanyIds = companyIds?.length && (!companyId || !companyIds.includes(companyId));
        const lockByRequiredPermission =
            requiredPermissions.length > 0 && !isAllowedByPermissions(requiredPermissions, permissions);
        const lockByRequiredRoleTypes =
            requiredRoleTypes.length > 0 && !isAllowedByUserRoleTypes(requiredRoleTypes, currentRoleType);

        if (lockByBlockIds || lockByCompanyIds || lockByRequiredPermission || lockByRequiredRoleTypes) {
            return false;
        }

        return true;
    };

    @action
    fetchAuthData = async () => {
        if (this.user?.userId && !this.isAnonymousUser) {
            const [profile, m2ProClientVerificationStatus, kycVerificationStatus] = await Promise.all([
                this.fetchProfile(),
                this.fetchM2ProClientVerificationStatus(),
                this.fetchKycVerificationStatus(),
            ]);
            this.profile = profile;
            this.m2ProClientVerificationStatus = m2ProClientVerificationStatus;
            this.kycVerificationStatus = kycVerificationStatus;
            this.publishAuthDataChanged();
        }
    };

    fetchProfile = async () => {
        if (this.user?.userId) {
            try {
                const { profile } = await getProfile({
                    urlParams: {
                        userId: this.user.userId,
                    },
                });
                return profile;
            } catch (error) {
                reportErrorToSentry({ error });
            }
        }
        return;
    };

    fetchM2ProClientVerificationStatus = async () => {
        if (this.isRealtor || this.isSuperUser) {
            const { userId, companyId } = this.user!;
            const params = companyId ? { companyId } : { userId };
            try {
                const { status } = await getVerificationStatus({ params });
                return status;
            } catch (error) {
                reportErrorToSentry({ error });
            }
        }
        return;
    };

    async fetchKycVerificationStatus() {
        const { userId } = this.user || {};
        try {
            const { status } = await findKycVerificationStatus({ params: { userId } });
            return status;
        } catch (error) {
            reportErrorToSentry({ error });
        }
        return;
    }

    @computed
    get isRealtor() {
        const roleType = this.currentRoleType;
        if (!roleType) return false;
        return [RoleType.Makler, RoleType.Professional].includes(roleType);
    }

    @computed
    get isProfessional() {
        return this.currentRoleType === RoleType.Professional;
    }

    @computed
    get currentRoleType() {
        return this.user?.currentRoleType;
    }

    @computed
    get isSuperUser() {
        return this.user?.currentRole === '37';
    }

    @computed
    get isUserNeedM2ProVerification() {
        return this.m2ProClientVerificationStatus !== 'STATUS_APPROVED';
    }

    @computed
    get isAlreadyPassedM2ProVerification() {
        return this.m2ProClientVerificationStatus && ['STATUS_APPROVED'].includes(this.m2ProClientVerificationStatus);
    }

    @computed
    get m2ProClientVerificationAvailable(): boolean {
        return this.isProfessional || this.checkPermissions([`/client-verification/m2pro/verification:read`]);
    }

    @computed
    get isM2ProClientVerificationAvailableToSend(): boolean {
        if (!this.m2ProClientVerificationStatus || !this.m2ProClientVerificationAvailable) {
            return false;
        }
        return ['STATUS_UNSPECIFIED', 'STATUS_DRAFT', 'STATUS_ADDITIONAL_DATA_REQUIRED'].includes(
            this.m2ProClientVerificationStatus,
        );
    }

    public checkPermissions = (testPermissions: string[] | undefined): boolean => {
        if (!testPermissions) return false;

        const regExp = new RegExp(testPermissions.join('|'));
        const userPermissions = this.user?.permissions || [];

        return userPermissions.some((permission) => regExp.test(permission));
    };

    private subscribeToHistoryChange() {
        this.historyStoreListener = this.rootStore.historyStore.history.listen(() => {
            this.checkIfSessionExpired(true);
            this.closeKycVerificationReminder();
        });
    }

    private subscribeToAuthInitialize() {
        this.eventBus.subscribe<AuthInitializeEventType, AuthInitializeEventData>('auth:initialize', (event) => {
            runInAction(() => {
                if (this.isAuthorized && !this.sessionExpired) {
                    this.publishAuthFinished();
                } else {
                    this.needLoadAuth = true;
                    this.authInitializeData = event.data;
                }
            });
        });
    }

    private subscribeToAuthLoaded() {
        this.eventBus.subscribe<AuthLoadedEventType, undefined>('auth:loaded', this.publishAuthInitialize);
    }

    private publishAuthInitialize = () => {
        if (!this.authInitializeData) return;

        const event: AuthInitialize = {
            type: 'auth:initialize',
            category: 'simple',
            data: this.authInitializeData,
        };
        this.eventBus.publish(event);
    };

    private publishAuthFinished(): void {
        const event: AuthFinished = {
            type: 'auth:finished',
            category: 'simple',
            data: {
                user: toJS(this.user),
                isAuthorized: this.isAuthorized,
            },
        };
        this.eventBus.publish(event);
    }

    private subscribeToAuthFinished() {
        this.eventBus.subscribe<AuthFinishedEventType, AuthFinishedEventData>('auth:finished', async (event) => {
            if (this.shouldReloadAfterAuthFinished) {
                location.reload();
            } else {
                runInAction(() => {
                    this.needLoadAuth = false;
                    this.authInitializeData = undefined;
                    this.user = event.data.user;
                    this.sessionExpired = false;
                    this.onAuthFinishedRedirectPath = event.data.redirectPath;
                });
            }
        });
    }

    // for backward compatibility, will be completely removed after sync with producers
    private subscribeUserDataChanged(): void {
        this.eventBus.subscribe<UserDataChangedEventType, AuthInfo>('auth:userDataChanged', (event) => {
            runInAction(() => {
                this.user = event.data.user;
            });
        });
    }

    private subscribeShowKycVerificarionReminder(): void {
        this.eventBus.subscribe<string, KycVerificationReminderData>('kyc-verification:show-reminder', (event) => {
            runInAction(() => {
                this.kycVerificationReminder = event.data;
            });
        });
    }

    private subscribeSessionExpired(): void {
        this.eventBus.subscribe<SessionExpiredEventType, Record<string, never>>('auth:sessionExpired', () => {
            this.logout();
            this.publishSessionExpiredNotification();
        });
    }

    private subscribeToCheckSessionExpired(): void {
        this.eventBus.subscribe<CheckSessionExpiredEventType, Record<string, never>>(
            'auth:checkIfSessionExpired',
            () => {
                this.checkIfSessionExpired();
            },
        );
    }

    private publishAppContextChanged(): void {
        const event: AppContextChanged = {
            type: 'app:contextChanged',
            data: {
                authInfo: this.toJS(),
                config: this.rootStore.configStore.toJS(),
                utm: this.rootStore.utmStore.utm,
                bundleTree: this.rootStore.bundleTreeStore.toJS(),
            },
            category: 'behavior',
        };
        this.eventBus.publish(event);
    }

    private publishAuthDataChanged(): void {
        const event: any = {
            type: 'app:authDataChanged',
            data: {
                profile: toJS(this.profile),
                m2Pro: {
                    clientVerificationStatus: this.m2ProClientVerificationStatus,
                    isClientVerificationAvailableToSend: this.isM2ProClientVerificationAvailableToSend,
                    isUserNeedVerification: this.isUserNeedM2ProVerification,
                },
                kycVerification: {
                    status: this.kycVerificationStatus,
                },
            },
            category: 'behavior',
        };

        this.eventBus.publish(event);
    }

    private setUpSentryContext() {
        const currentScope = Sentry?.getCurrentHub().getStack()[0]?.scope;
        if (currentScope && this.isAuthorized) {
            currentScope.setUser({
                id: this.user?.userId || 'authorized but without user or userId',
                username: this.user?.username || undefined,
                currentRoleType: this.user?.currentRoleType,
                currentRoleDisplayName: this.user?.currentRoleDisplayName,
                companyId: this.user?.companyId,
                roles: JSON.stringify(this.user?.roles),
                permissions: JSON.stringify(this.user?.permissions),
            });
        }
    }

    private setUpWebAnalytics() {
        if (!this.user) return;

        window.dataLayer.push({
            userId: this.user.userId,
            role: this.user.currentRole,
        });

        if (this.user.permissions.includes('/classified/mls/search:read')) {
            window.dataLayer.push({
                event: 'mls-scoped-user',
                userId: this.user.userId,
                mls: true,
            });
        }
    }

    private publishSessionExpiredNotification() {
        const sessionExpiredEventPayload: NotificationPayload = {
            type: 'default',
            message: 'Сессия истекла. Выполните повторный вход.',
        };
        const sessionExpiredEvent: NotificationEvent = {
            type: 'app:notification',
            data: sessionExpiredEventPayload,
            category: 'simple',
        };
        this.eventBus.publish(sessionExpiredEvent);
    }
}
